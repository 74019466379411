<template>
    <div>
        <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" :aria-labelledby="modalId + 'Label'" aria-hidden="false"
             data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">

                        <h3 class="modal-title text-dark" v-html="gdprSelected.title"></h3>
                        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button> -->
                    </div>
                    <div class="modal-body text-dark">
                        <div class="p-4">
                            <p v-html="gdprSelected.par1"></p>
                            <p v-html="gdprSelected.par2"></p>
                        </div>
                    </div>
                    <div class="modal-footer">
                       <!-- <button type="button" class="btn btn-sm btn-link px-3 py-2" data-dismiss="modal">{{ $t('deny') }}</button> -->
                        <button type="button" class="btn btn-sm btn-light border rounded mr-5 px-3 py-2 text-capitalize" @click="closeGdpr" v-html="gdprSelected.button"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'trigger',
            'gdprText'
        ],
        methods: {
            closeGdpr: function() {
                window.$('#' + this.modalId).modal('hide')
                localStorage.setItem('gdpr', true);
            }
        },
        name: 'FeedbackModal',
        data: function () {
            return {
                modalId: 'gdpr_modal',
                gdprSelected: {
                    title: '',
                    par1: '',
                    par2: '',
                    button: ''
                }
            }
        },
        created() {
            if(this.trigger && !localStorage.getItem('gdpr')) {
                let searchGdprText = this.gdprText.find((tmpGdpr => (tmpGdpr.langcode.indexOf(navigator.language) > -1)));
                if(typeof (searchGdprText) != 'undefined') {
                    this.gdprSelected = searchGdprText;
                } else {
                    this.gdprSelected = this.gdprText.find((tmpGdpr => (typeof (tmpGdpr.default) != 'undefined' && tmpGdpr.default)));
                }

                setTimeout(() => {
                    window.$('#' + this.modalId).modal('show')
                }, 500)
            }

        }
    }
</script>

<style scoped>

</style>
